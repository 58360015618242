import styled from 'styled-components';

import colorTheme from '../../../style/colorTheme';
import { desktopOnly } from '../../../style/desktopHelpers';
import themeGet from '../../../style/themeGet';
import { B2, Caption, H2, H3, H4 } from '../../Atoms/Typography/Typography';
import { SiteGrid } from '../../SiteGrid/SiteGrid';
import { ButtonLink } from '../../Atoms/Button/Button';
import { Button } from '../../Atoms/Button/Button';
import { ITheme } from '../../../types/types';
import { TabProps } from './Locations';

export const ThemeContainer = styled.div<any>`
  ${({ ezratheme }) => colorTheme(ezratheme)};
  background-color: var(--background);
`;

export const StyledSiteGrid = styled(SiteGrid)`
  ${desktopOnly} {
    //padding-top: ${themeGet('spacing.s3')};
    grid-template-columns: repeat(8, 1fr);
  }
  grid-template-columns: unset;
`;

export const StyledH3Container = styled.div`
  display: grid;
  grid-column: 1 / -1;
  padding: ${themeGet('spacing.mobile.s4')} 0 ${themeGet('spacing.mobile.s3')};

  ${desktopOnly} {
    grid-column: 1 / 2;
    margin: 0;
  }
`;

export const StyledH3 = styled(H3)`
  color: var(--highlight);
`;

export const RedesignStyledH3 = styled(H3)`
  font-size: 4.6rem;
  line-height: 1.1;
  ${desktopOnly} {
    margin: ${themeGet('spacing.mobile.s3')} 0 ${themeGet('spacing.mobile.s1')};
  }
`;

export const ParentContainer = styled.div`
  display: grid;
  grid-column: 1 / -1;
  margin-bottom: ${themeGet('spacing.mobile.s1')};

  ${desktopOnly} {
    display: flex;
    grid-column: 3 / -1;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 3px;
    margin-bottom: ${themeGet('spacing.s1')};
  }
`;

export const RedesignParentContainer = styled(ParentContainer)`
  margin-bottom: 0;
`;

export const ChildContainer = styled.div`
  display: grid;
  grid-column: 1 / 1;
  margin-bottom: ${themeGet('spacing.mobile.s2')};

  ${desktopOnly} {
    width: 33%;
    margin-bottom: ${themeGet('spacing.s3')};
  }
`;

export const RedesignChildContainer = styled.div`
  margin-bottom: ${themeGet('spacing.mobile.s2')};

  ${desktopOnly} {
    width: 33%;
    margin-bottom: ${themeGet('spacing.s3')};
  }
`;

export const StyledCityContainer = styled.div`
  display: grid;
  grid-column: 1 / -1;
`;

export const StyledCity = styled(H4)`
  color: var(--highlight);
`;

export const RedesignStyledCity = styled(H4)`
  color: var(--highlight);
  margin-bottom: 5px;
  font-size: 2.5rem;
`;

export const StyledDiv = styled.div`
  display: grid;
  grid-column: 1 / -1;
  margin: ${themeGet('spacing.mobile.s6')} 0 ${themeGet('spacing.mobile.s5')};
`;

export const StyledB2 = styled(B2)`
  color: var(--highlight);
`;

export const AddressContainer = styled.div`
  display: grid;
  grid-column: 1 / -1;
  max-width: 50%;

  ${desktopOnly} {
    max-width: 100%;
  }
`;

export const HrContainer = styled.div`
  display: none;
  ${desktopOnly} {
    display: block;
    width: 100%;
    margin: ${themeGet('spacing.mobile.s1')} 0;

    hr {
      background-color: var(--primary);
      height: 1px;
    }
  }
`;

export const LeftPanel = styled.div`
  display: grid;
  grid-column: 1 / -1;
  background-color: ${themeGet('color.primary.graphite')};

  ${desktopOnly} {
    grid-column: 1 / 4;
  }
`;

export const H2Container = styled.div`
  display: grid;
  grid-column: 1 / -1;
  margin: ${themeGet('spacing.mobile.s5')} 0 ${themeGet('spacing.mobile.s5')};

  ${desktopOnly} {
    grid-column: 1 / 4;
  }
`;

export const StyledH2 = styled(H2)`
  //color: ${themeGet('color.primary.yellow')};
  color: ${themeGet('color.primary.white')};
  font-size: 4rem;
`;

export const StyledButtonLink = styled(ButtonLink)`
  margin-top: 5px;
  margin-bottom: ${themeGet('spacing.mobile.s3')};
  padding: 14px 28px 14px;
  font-size: 1.6rem;
  width: fit-content;
  background-color: ${themeGet('color.primary.yellow')};
  :hover {
    background-color: ${themeGet('color.primary.yellow')};
    color: ${themeGet('color.primary.graphite')};
  }

  :hover svg path {
    stroke: var(--primary);
    fill: var(--primary);
  }
`;

export const B2Container = styled.div`
  display: grid;
  grid-column: 1 / -1;
  margin-bottom: ${themeGet('spacing.mobile.s3')};

  ${desktopOnly} {
    grid-column: 1 / 4;
  }
`;

export const StyledB2Rich = styled(B2)`
  color: ${themeGet('color.primary.white')};
  font-size: 1.8rem;
`;

export const ItemsParentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
  margin-bottom: ${themeGet('spacing.mobile.s1')};

  ${desktopOnly} {
    grid-column: 1 / 4;
  }
`;

export const StyledH2Number = styled(H2)`
  margin-bottom: 12px;
  font-size: 6rem;
  color: ${themeGet('color.primary.white')};
`;

export const StyledCaption = styled(Caption)`
  font-size: 1.6rem;
  text-transform: none;
  font-weight: 300;
  color: ${themeGet('color.primary.white')};
`;

export const MapsContainer = styled.div`
  background-color: ${themeGet('color.primary.graphite')};

  ${desktopOnly} {
    padding-top: ${themeGet('spacing.s5')};
  }
`;

export const RightPanel = styled.div`
  display: flex;
  justify-content: center;
  grid-column: unset;
  padding: 5px 0 ${themeGet('spacing.mobile.s6')};

  ${desktopOnly} {
    display: flex;
    justify-content: flex-start;
    grid-column: 4 / 9;
    padding: 0;
  }
`;

export const StyledButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 0;
  position: relative;
  left: 7%;
  bottom: 3.75%;

  ${desktopOnly} {
    left: 17%;
    svg {
      max-width: 100%;
    }
  }
  svg {
    max-width: 100%;
    height: 100%;
  }
`;

export const StyledAPACButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 0;
  height: 100%;
  position: relative;
  right: 10%;
  ${desktopOnly} {
    right: 0;
    svg {
      max-width: 100%;
    }
  }
  svg {
    max-width: 100%;
    height: 100%;
  }
`;

export const AmericasContainer = styled.div`
  display: flex;
  ${desktopOnly} {
    width: 40%;
    padding: 0 16px;
  }
`;

export const EMEAAPACContainer = styled.div`
  display: flex;
  ${desktopOnly} {
    width: 60%;
  }
  height: 100%;
`;

export const TabsContainer = styled.div`
  background-color: ${themeGet('color.primary.yellow')};
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
  width: 100%;
  margin: 38px 0 25px;

  ${desktopOnly} {
    grid-column: 1 / 4;
  }
`;

export const RedesignTabsContainer = styled.div<any>`
  ${({ ezratheme }) => colorTheme(ezratheme)};
  background-color: #d4d4c5;
`;

export const RedesignTab = styled(Button)<TabProps>`
  ${({ active }) =>
    active &&
    `
    background-color: var(--background);
    font-weight: 700;
  `}

  ${desktopOnly} {
    width: 200px;
    height: 90px;
  }
  width: 33.3%;
  height: 70px;
  border-right: 1px solid #b8b8b3;
  font-size: 2rem;

  :after {
    content: '';
    bottom: 25px;
    width: 60%;
    left: 20%;
    @media (max-width: 540px) {
      width: 75%;
      left: 12%;
    }
  }
`;

export const TeamGridContainer = styled.div<ITheme>`
  ${({ ezratheme }) => colorTheme(ezratheme)};
  background-color: var(--background);
  display: grid;
`;

export const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  ${desktopOnly} {
    padding: 0 40px;
  }
`;

export const UnderlinedContainer = styled.div`
  display: flex;
  margin: 0 30px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid black;

  ${desktopOnly} {
    border-bottom: none;
    margin: 0;
  }
`;

export const Tab = styled.button<TabProps>`
  cursor: pointer;
  background: ${themeGet('color.primary.yellow')};
  border: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
  `}
`;

export const StyledH4 = styled(H4)`
  color: ${themeGet('color.primary.graphite')};
`;
