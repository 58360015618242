import React, { useState, useContext } from 'react';

import {
  ThemeContainer,
  StyledSiteGrid,
  StyledH3Container,
  StyledH3,
  ParentContainer,
  ChildContainer,
  StyledCityContainer,
  StyledCity,
  StyledDiv,
  StyledB2,
  AddressContainer,
  HrContainer,
  LeftPanel,
  H2Container,
  StyledH2,
  B2Container,
  StyledB2Rich,
  ItemsParentContainer,
  StyledH2Number,
  StyledCaption,
  MapsContainer,
  RightPanel,
  StyledButton,
  EMEAAPACContainer,
  StyledAPACButton,
  AmericasContainer,
  StyledH4,
  StyledButtonLink,
  RedesignTabsContainer,
  RedesignTab,
  RedesignStyledH3,
  RedesignParentContainer,
  RedesignStyledCity,
  RedesignChildContainer
} from './styles';
import { renderRichTextNode } from '../../../hooks/useRichText';
import AmericasIcon from '../../../images/svgs/LocationsMap/Americas.svg';
import AmericasIconHighlighted from '../../../images/svgs/LocationsMap/AmericasHighlighted.svg';
import APACIconHighlighted from '../../../images/svgs/LocationsMap/APAC-Highlighted.svg';
import APACIcon from '../../../images/svgs/LocationsMap/APAC.svg';
import EMEAIconHighlighted from '../../../images/svgs/LocationsMap/EMEA-Highlighted.svg';
import EMEAIcon from '../../../images/svgs/LocationsMap/EMEA.svg';
import { SiteGrid } from '../../SiteGrid/SiteGrid';
import Wrapper from '../../Wrapper/Wrapper';
import { TabsContainer, UnderlinedContainer, Tab } from '../TeamGrid/styles';
import PageContext from '../../../hooks/pageContextProvider';
import { getLocalePage } from '../../../hooks/useLocale';

export interface LocationsDataProps {
  // node: {
  //   primaryText:
  //     | boolean
  //     | React.ReactChild
  //     | React.ReactFragment
  //     | React.ReactPortal
  //     | null
  //     | undefined;
  //   secondaryText:
  //     | boolean
  //     | React.ReactChild
  //     | React.ReactFragment
  //     | React.ReactPortal
  //     | null
  //     | undefined;
  //   bodyText: { raw: any };
  // };
  primaryText?: string;
  secondaryText?: string;
  bodyText?: { raw: any };
  id: string;
}

export interface TabProps {
  active?: boolean;
}

// const Tabs = ({ content, active, switchTabs }) => {
//   return (
//     <TabsContainer ezratheme={'Highlight'}>
//       <UnderlinedContainer>
//         {content?.map((tab, idx) => (
//           <Tab
//             key={idx}
//             active={active === tab.pageParameters?.[0]?.value}
//             onClick={() => switchTabs(tab.pageParameters?.[0]?.value)}
//           >
//             <StyledH4 as="span">{tab.text}</StyledH4>
//           </Tab>
//         ))}
//       </UnderlinedContainer>
//     </TabsContainer>
//   );
// };
{
  /* New component styling for redesign. Comment code below and uncomment the above code if old styling is needed: */
}
const Tabs = ({ content, active, switchTabs }) => {
  return (
    <RedesignTabsContainer ezratheme={'Highlight'}>
      {content?.map((tab, idx) => (
        <RedesignTab
          key={idx}
          variant="secondary"
          active={active === tab.pageParameters?.[0]?.value}
          onClick={() => switchTabs(tab.pageParameters?.[0]?.value)}
        >
          {tab.text}
        </RedesignTab>
      ))}
    </RedesignTabsContainer>
  );
};

const Locations = ({ navData, locationsData, mapData }) => {
  const { locale } = useContext(PageContext);
  const [active, setActive] = useState(
    navData?.navigationLinks?.[0].pageParameters?.[0]?.value,
  );
  const [filteredLocationList, setFilteredLocationList] = useState(
    locationsData?.contentItems?.filter(
      (location) =>
        location?.region.toLowerCase() ===
        navData?.navigationLinks?.[0].pageParameters?.[0]?.value.toLowerCase(),
    ),
  );
  const switchNavTabs = (tab: string) => {
    setActive(tab);
    setFilteredLocationList(
      locationsData?.contentItems?.filter(
        (location) => location?.region.toLowerCase() === tab.toLowerCase(),
      ),
    );
  };

  return (
    <>
      <MapsContainer>
        <Wrapper>
          <StyledSiteGrid>
            <LeftPanel>
              <H2Container>
                <StyledH2 as="h1">{mapData?.content?.primaryText}</StyledH2>
              </H2Container>
              <B2Container>
                <StyledB2Rich>
                  {mapData?.content?.bodyText?.raw &&
                    renderRichTextNode(mapData?.content?.bodyText)}
                </StyledB2Rich>
              </B2Container>
              {mapData?.content?.link && (
                <StyledButtonLink
                  to={getLocalePage(mapData.content.link?.[0], locale)}
                  variant="primary"
                  withChevron
                >
                  {mapData.content?.link?.[0]?.text}
                </StyledButtonLink>
              )}
              <ItemsParentContainer>
                {mapData?.contentItems?.map((item) => (
                  <div key={item?.secondaryText}>
                    <StyledH2Number>{item?.primaryText}</StyledH2Number>
                    <StyledCaption>{item?.secondaryText}</StyledCaption>
                  </div>
                ))}
              </ItemsParentContainer>
            </LeftPanel>
            <RightPanel>
              <AmericasContainer>
                <StyledButton
                  onClick={() => {
                    switchNavTabs(
                      navData?.navigationLinks[0]?.pageParameters?.[0]?.value,
                    );
                  }}
                  aria-label={
                    navData?.navigationLinks[0]?.pageParameters?.[0]?.value
                  }
                >
                  {active ===
                  navData?.navigationLinks[0]?.pageParameters?.[0]?.value ? (
                    <AmericasIconHighlighted />
                  ) : (
                    <AmericasIcon />
                  )}
                </StyledButton>
              </AmericasContainer>
              <EMEAAPACContainer>
                <StyledButton
                  onClick={() =>
                    switchNavTabs(
                      navData?.navigationLinks[1]?.pageParameters?.[0]?.value,
                    )
                  }
                  aria-label={
                    navData?.navigationLinks[1]?.pageParameters?.[0]?.value
                  }
                >
                  {active ===
                  navData?.navigationLinks[1]?.pageParameters?.[0]?.value ? (
                    <EMEAIconHighlighted />
                  ) : (
                    <EMEAIcon />
                  )}
                </StyledButton>
                <StyledAPACButton
                  onClick={() =>
                    switchNavTabs(
                      navData?.navigationLinks[2]?.pageParameters?.[0]?.value,
                    )
                  }
                  aria-label={
                    navData?.navigationLinks[2]?.pageParameters?.[0]?.value
                  }
                >
                  {active ===
                  navData?.navigationLinks[2]?.pageParameters?.[0]?.value ? (
                    <APACIconHighlighted />
                  ) : (
                    <APACIcon />
                  )}
                </StyledAPACButton>
              </EMEAAPACContainer>
            </RightPanel>
          </StyledSiteGrid>
        </Wrapper>
      </MapsContainer>

      <Tabs
        content={navData?.navigationLinks}
        active={active}
        switchTabs={switchNavTabs}
      />
      <ThemeContainer ezratheme={locationsData?.appearance?.theme || 'Light'}>
        <Wrapper>
          {/* Old component styling:
           <StyledSiteGrid>
            <StyledH3Container>
              <StyledH3>{locationsData?.content?.primaryText}</StyledH3>
            </StyledH3Container>
            <ParentContainer>
              {filteredLocationList?.map(
                (location: LocationsDataProps, index: number) => (
                  <>
                    <ChildContainer key={location?.id}>
                      <StyledCityContainer>
                        <StyledCity>{location?.primaryText}</StyledCity>
                      </StyledCityContainer>
                      <StyledDiv>
                        <StyledB2>{location?.secondaryText}</StyledB2>
                      </StyledDiv>
                      <AddressContainer>
                        <StyledB2>
                          {location?.bodyText?.raw &&
                            renderRichTextNode(location?.bodyText)}
                        </StyledB2>
                      </AddressContainer>
                    </ChildContainer>
                    {(index + 1) % 3 === 0 &&
                      filteredLocationList.length > 3 && (
                        <HrContainer>
                          <hr />
                        </HrContainer>
                      )}
                  </>
                ),
              )}
            </ParentContainer>
          </StyledSiteGrid> */}
          {/* New component styling for redesign. Comment code below and uncomment the above code if old styling is needed: */}
          <StyledH3Container>
            <RedesignStyledH3>
              {locationsData?.content?.primaryText}
            </RedesignStyledH3>
          </StyledH3Container>
          <RedesignParentContainer>
            {filteredLocationList?.map(
              (location: LocationsDataProps, index: number) => (
                <>
                  <RedesignChildContainer key={location?.id}>
                    <StyledCityContainer>
                      <RedesignStyledCity>
                        {location?.primaryText}
                      </RedesignStyledCity>
                    </StyledCityContainer>
                    <AddressContainer>
                      <StyledB2>
                        {location?.bodyText?.raw &&
                          renderRichTextNode(location?.bodyText)}
                      </StyledB2>
                    </AddressContainer>
                  </RedesignChildContainer>
                </>
              ),
            )}
          </RedesignParentContainer>
        </Wrapper>
      </ThemeContainer>
    </>
  );
};

export default Locations;
