import React, { useContext, useEffect } from 'react';

import { graphql, navigate, PageProps } from 'gatsby';

import AccordionSection from '../components/FlexibleComponents/AccordionSection/AccordionSection';
import CardCarousel from '../components/FlexibleComponents/CardCarousel/CardCarousel';
import CardRowSimple from '../components/FlexibleComponents/CardRowSimple/CardRowSimple';
import CopyIconGrid from '../components/FlexibleComponents/CopyIconGrid/CopyIconGrid';
import CTABanner from '../components/FlexibleComponents/CTABanner/CTABanner';
import CTAGrid from '../components/FlexibleComponents/CTAGrid/CTAGrid';
import CTAText from '../components/FlexibleComponents/CTAText/CTAText';
import FeatureText from '../components/FlexibleComponents/FeatureText/FeatureText';
import FourCardRowSimple from '../components/FlexibleComponents/FourCardRowSimple/FourCardRowSimple';
import HeroBanner from '../components/FlexibleComponents/HeroBanner/HeroBanner';
import ImageCopy from '../components/FlexibleComponents/ImageCopy/ImageCopy';
import ImageCopyStatsRow from '../components/FlexibleComponents/ImageCopyStatsRow/ImageCopyStatsRow';
import ImageCopyTabbed from '../components/FlexibleComponents/ImageCopyTabbed/ImageCopyTabbed';
import ImageCopyVerticalTabbed from '../components/FlexibleComponents/ImageCopyVerticalTabbed/ImageCopyVerticalTabbed';
import Locations from '../components/FlexibleComponents/Locations/Locations';
import LogoGrid from '../components/FlexibleComponents/LogoGrid/LogoGrid';
import RichText from '../components/FlexibleComponents/RichText/RichText';
import StackedSet from '../components/FlexibleComponents/StackedSet/StackedSet';
import StatsColumn from '../components/FlexibleComponents/StatsColumn/StatsColumn';
import TeamGrid from '../components/FlexibleComponents/TeamGrid/TeamGrid';
import TestimonialCarousel from '../components/FlexibleComponents/TestimonialCarousel/TestimonialCarousel';
import TitleCopyGrid from '../components/FlexibleComponents/TitleCopyGrid/TitleCopyGrid';
import TitleCopyTwoColumn from '../components/FlexibleComponents/TitleCopyTwoColumn/TitleCopyTwoColumn';
import VideoCopyHero from '../components/FlexibleComponents/VideoCopyHero/VideoCopyHero';
import VideoCopyStacked from '../components/FlexibleComponents/VideoCopyStacked/VideoCopyStacked';
import FormComponent from '../components/Forms/FormComponent/FormComponent';
import ABCarousel from '../components/Molecules/Carousel/ABCarousel';
import LogoCarousel from '../components/Molecules/Carousel/LogoCarousel';
import LogoImagesCarousel from '../components/Molecules/Carousel/LogoImagesCarousel';
import LogoImagesWithTextCarousel from '../components/Molecules/Carousel/LogoImagesWithTextCarousel';
import TickerCarousel from '../components/Molecules/Carousel/TickerCarousel';
import VideoCarousel from '../components/Molecules/Carousel/VideoCarousel';
import SEO from '../components/SEO/SEO';
import { SiteFooter } from '../components/SiteFooter/SiteFooter';
import SiteHeader from '../components/SiteHeader/SiteHeader';
import PageContext from '../hooks/pageContextProvider';
import { IFlexibleComponent } from '../types/types';
import replaceLocaleUrl from '../utils/replaceLocaleUrl';

export const mapSections = (sections: IFlexibleComponent[]) =>
  sections?.map((section: any, index) => {
    switch (section?.layout) {
      case 'Accordion':
        return <AccordionSection key={section.id} {...section} />;
      case 'HeroBanner':
        return <HeroBanner key={section.id} {...section} />;
      case 'FeatureText':
        return <FeatureText key={section.id} {...section} />;
      case 'CardCarousel':
        return <CardCarousel key={section.id} {...section} />;
      case 'CopyIconGrid':
        return <CopyIconGrid key={section.id} {...section} />;
      case 'CTABanner':
        return <CTABanner key={section.id} {...section} />;
      case 'CTAGrid':
        return <CTAGrid key={section.id} {...section} />;
      case 'CTAText':
        return <CTAText key={section.id} {...section} />;
      case 'ImageCopy':
        return <ImageCopy key={section.id} {...section} />;
      case 'ImageCopyTabbed':
        return <ImageCopyTabbed key={section.id} {...section} />;
      case 'LogoCarousel':
        return (
          <LogoCarousel
            key={section.id}
            slides={section.contentItems}
            ezratheme={section?.appearance?.theme}
          />
        );
      case 'LogoImagesCarousel':
        return (
          <LogoImagesCarousel
            key={section.id}
            slides={section.contentItems}
            ezratheme={section?.appearance?.theme}
          />
        );
      case 'LogoImagesWithTextCarousel':
        return (
          <LogoImagesWithTextCarousel
            key={section.id}
            slides={section.contentItems}
            content={section.content}
            ezratheme={section?.appearance?.theme}
          />
        );
      case 'LogoGrid':
        return <LogoGrid key={section.id} {...section} />;
      case 'RichText':
        return <RichText key={section.id} index={index} {...section} />;
      case 'NewsletterSignUp':
        return <FormComponent key={section.id} {...section} />;
      case 'StatsColumn':
        return <StatsColumn key={section.id} {...section} />;
      case 'TextTickerCTA':
        return (
          <TickerCarousel
            key={section.id}
            title={section.content?.primaryText}
            cta={section.content?.link?.[0]}
            slides={section.contentItems}
            ezratheme={section.appearance?.theme}
          />
        );
      case 'VideoCopyCarousel':
        return <VideoCarousel key={section.id} slides={section.contentItems} />;
      case 'CardRowSimple':
        return <CardRowSimple key={section.id} {...section} />;
      case 'FourCardRowSimple':
        return <FourCardRowSimple key={section.id} {...section} />;
      case 'StackedSet':
        return <StackedSet key={section.id} {...section} />;
      case 'ImageCopyCarousel':
        return (
          <ABCarousel
            key={section.id}
            slides={section.contentItems}
            ezratheme={section?.appearance?.theme}
          />
        );
      case 'TitleCopyGrid':
        return <TitleCopyGrid key={section.id} {...section} />;
      case 'VideoCopyHero':
        return <VideoCopyHero key={section.id} {...section} />;
      case 'VideoCopyStacked':
        return <VideoCopyStacked key={section.id} {...section} />;
      case 'VideoCopyStatsRow':
      case 'ImageCopyStatsRow':
        return <ImageCopyStatsRow key={section.id} {...section} />;
      case 'TitleCopyTwoColumn':
        return <TitleCopyTwoColumn key={section.id} {...section} />;
      case 'TestimonialCarousel':
        return <TestimonialCarousel key={section.id} {...section} />;
      case 'ImageCopyVerticalTabbed':
        return <ImageCopyVerticalTabbed key={section.id} {...section} />;
      case 'WhoWeAreGrid':
        return <TeamGrid key={section.id} {...section} />;
      case 'TitleCopyCTAHero':
        console.log('locations');
        return <VideoCopyStacked key={section.id} index={index} {...section} />;
    }
  });

const LocationsPage: React.FC<PageProps<any>> = (props: any) => {
  const website = props.data?.contentfulWebsite;
  const pageData = props.data.contentfulComposePage;
  const seo = pageData.seo;
  const sections = pageData.content.sections;
  const headerData = props.data?.allContentfulComponentSiteHeader?.nodes?.[0];
  const stickyBanner = props.data?.allContentfulComponentStickyBanner?.nodes;
  const footerData = props.data?.allContentfulComponentSiteFooter?.nodes?.[0];
  const customTheme = pageData?.customTheme;
  const slug = pageData?.slug;
  const location = props.location;
  const alternativeLocales = props.data?.alternativeLocales?.distinct || [];

  const MapStatsHero = sections[0];
  const LocationsNavBar = sections[1];
  const LocationGrid = sections[2];
  const { locale } = useContext(PageContext);

  useEffect(() => {
    const isFrenched = localStorage.getItem('isFrenched');
    if (isFrenched) {
      return;
    }

    fetch('https://ipapi.co/json/', { method: 'GET' })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data?.region === 'Quebec') {
          localStorage.setItem('isFrenched', 'true');
          const neededUrl = replaceLocaleUrl(location?.pathname, 'fr-fr');
          return navigate(neededUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <SEO
        siteName={website?.siteName}
        title={pageData.title}
        description={
          seo?.description ? seo?.description.description : pageData.title
        }
        location={location}
        alternativeLocales={alternativeLocales}
        slug={slug}
        noIndex={seo?.seoIndex === 'No'}
        image={seo?.shareImage}
        locale={locale}
      />
      <SiteHeader
        content={headerData}
        stickyBanner={stickyBanner}
        customTheme={customTheme}
      />
      <main id="main-content" role="main">
        <Locations
          navData={LocationsNavBar}
          mapData={MapStatsHero}
          locationsData={LocationGrid}
        />
        {sections && mapSections(sections)}
      </main>
      <SiteFooter {...footerData} customTheme={customTheme} />
    </>
  );
};

export default LocationsPage;

export const data = graphql`
  query LocationsPageQuery(
    $id: String
    $locale: String
    $contentful_id: String
  ) {
    contentfulWebsite {
      siteName
    }
    alternativeLocales: allContentfulComposePage(
      filter: {
        contentful_id: { eq: $contentful_id }
        publishInLocale: { eq: "Yes" }
      }
    ) {
      distinct(field: node_locale)
    }
    contentfulComposePage(id: { eq: $id }) {
      id
      contentful_id
      title
      slug
      customTheme
      content {
        ...LocationsPageFragment
      }
      seo {
        ...SEOFragment
      }
    }

    allContentfulComponentStickyBanner(
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        ...StickyBannerFragment
      }
    }

    allContentfulComponentSiteHeader(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...SiteHeaderFragment
      }
    }

    allContentfulComponentSiteFooter(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...SiteFooterFragment
      }
    }

    allContentfulContentMicrocopy(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...MicroCopyFragment
      }
    }
  }
`;
